
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("junior/adapters/activity-answer", function(){ return i("junior/adapters/activity-answer.js");});
d("junior/adapters/activity", function(){ return i("junior/adapters/activity.js");});
d("junior/adapters/application", function(){ return i("junior/adapters/application.js");});
d("junior/adapters/assessment", function(){ return i("junior/adapters/assessment.js");});
d("junior/adapters/challenge", function(){ return i("junior/adapters/challenge.js");});
d("junior/adapters/school", function(){ return i("junior/adapters/school.js");});
d("junior/app", function(){ return i("junior/app.js");});
d("junior/config/environment", function(){ return i("junior/config/environment.js");});
d("junior/initializers/register-showdown-extensions", function(){ return i("junior/initializers/register-showdown-extensions.js");});
d("junior/models/activity-answer", function(){ return i("junior/models/activity-answer.js");});
d("junior/models/activity", function(){ return i("junior/models/activity.js");});
d("junior/models/assessment", function(){ return i("junior/models/assessment.js");});
d("junior/models/challenge", function(){ return i("junior/models/challenge.js");});
d("junior/models/mission", function(){ return i("junior/models/mission.js");});
d("junior/models/organization-learner", function(){ return i("junior/models/organization-learner.js");});
d("junior/models/school", function(){ return i("junior/models/school.js");});
d("junior/router", function(){ return i("junior/router.js");});
d("junior/serializers/application", function(){ return i("junior/serializers/application.js");});
d("junior/services/current-learner", function(){ return i("junior/services/current-learner.js");});
d("junior/services/store", function(){ return i("junior/services/store.js");});
d("junior/transforms/boolean", function(){ return i("junior/transforms/boolean.js");});
d("junior/transforms/string", function(){ return i("junior/transforms/string.js");});
d("junior/utils/embed-allowed-origins", function(){ return i("junior/utils/embed-allowed-origins.js");});
d("junior/utils/generate-random-string", function(){ return i("junior/utils/generate-random-string.js");});
d("junior/utils/labeled-checkboxes", function(){ return i("junior/utils/labeled-checkboxes.js");});
d("junior/utils/layout", function(){ return i("junior/utils/layout.js");});
d("junior/utils/markdown-converter", function(){ return i("junior/utils/markdown-converter.js");});
d("junior/utils/proposals-as-array", function(){ return i("junior/utils/proposals-as-array.js");});
d("junior/utils/proposals-as-blocks", function(){ return i("junior/utils/proposals-as-blocks.js");});
d("junior/utils/proposals-parser/challenge-response-template", function(){ return i("junior/utils/proposals-parser/challenge-response-template.js");});
d("junior/utils/proposals-parser/input-block", function(){ return i("junior/utils/proposals-parser/input-block.js");});
d("junior/utils/proposals-parser/response-block", function(){ return i("junior/utils/proposals-parser/response-block.js");});
d("junior/utils/proposals-parser/select-block", function(){ return i("junior/utils/proposals-parser/select-block.js");});
d("junior/utils/proposals-parser/splitters", function(){ return i("junior/utils/proposals-parser/splitters.js");});
d("junior/utils/proposals-parser/text-block", function(){ return i("junior/utils/proposals-parser/text-block.js");});
d("junior/utils/pshuffle", function(){ return i("junior/utils/pshuffle.js");});
d("junior/utils/value-as-array-of-boolean", function(){ return i("junior/utils/value-as-array-of-boolean.js");});
d("junior/services/page-title", function(){ return i("junior/services/page-title.js");});
d("junior/services/element-helper", function(){ return i("junior/services/element-helper.js");});
d("junior/stories/form.stories", function(){ return i("junior/stories/form.stories.js");});
d("junior/stories/pix-background-header.stories", function(){ return i("junior/stories/pix-background-header.stories.js");});
d("junior/stories/pix-banner.stories", function(){ return i("junior/stories/pix-banner.stories.js");});
d("junior/stories/pix-block.stories", function(){ return i("junior/stories/pix-block.stories.js");});
d("junior/stories/pix-button-link.stories", function(){ return i("junior/stories/pix-button-link.stories.js");});
d("junior/stories/pix-button-upload.stories", function(){ return i("junior/stories/pix-button-upload.stories.js");});
d("junior/stories/pix-button.stories", function(){ return i("junior/stories/pix-button.stories.js");});
d("junior/stories/pix-checkbox-variant-tile.stories", function(){ return i("junior/stories/pix-checkbox-variant-tile.stories.js");});
d("junior/stories/pix-checkbox.stories", function(){ return i("junior/stories/pix-checkbox.stories.js");});
d("junior/stories/pix-collapsible.stories", function(){ return i("junior/stories/pix-collapsible.stories.js");});
d("junior/stories/pix-filter-banner.stories", function(){ return i("junior/stories/pix-filter-banner.stories.js");});
d("junior/stories/pix-filterable-and-searchable-select.stories", function(){ return i("junior/stories/pix-filterable-and-searchable-select.stories.js");});
d("junior/stories/pix-icon-button.stories", function(){ return i("junior/stories/pix-icon-button.stories.js");});
d("junior/stories/pix-indicator-card.stories", function(){ return i("junior/stories/pix-indicator-card.stories.js");});
d("junior/stories/pix-input-code.stories", function(){ return i("junior/stories/pix-input-code.stories.js");});
d("junior/stories/pix-input-password.stories", function(){ return i("junior/stories/pix-input-password.stories.js");});
d("junior/stories/pix-input.stories", function(){ return i("junior/stories/pix-input.stories.js");});
d("junior/stories/pix-label.stories", function(){ return i("junior/stories/pix-label.stories.js");});
d("junior/stories/pix-message.stories", function(){ return i("junior/stories/pix-message.stories.js");});
d("junior/stories/pix-modal.stories", function(){ return i("junior/stories/pix-modal.stories.js");});
d("junior/stories/pix-multi-select.stories", function(){ return i("junior/stories/pix-multi-select.stories.js");});
d("junior/stories/pix-pagination.stories", function(){ return i("junior/stories/pix-pagination.stories.js");});
d("junior/stories/pix-progress-gauge.stories", function(){ return i("junior/stories/pix-progress-gauge.stories.js");});
d("junior/stories/pix-radio-button-variant-tile.stories", function(){ return i("junior/stories/pix-radio-button-variant-tile.stories.js");});
d("junior/stories/pix-radio-button.stories", function(){ return i("junior/stories/pix-radio-button.stories.js");});
d("junior/stories/pix-return-to.stories", function(){ return i("junior/stories/pix-return-to.stories.js");});
d("junior/stories/pix-search-input.stories", function(){ return i("junior/stories/pix-search-input.stories.js");});
d("junior/stories/pix-select.stories", function(){ return i("junior/stories/pix-select.stories.js");});
d("junior/stories/pix-selectable-tag.stories", function(){ return i("junior/stories/pix-selectable-tag.stories.js");});
d("junior/stories/pix-sidebar.stories", function(){ return i("junior/stories/pix-sidebar.stories.js");});
d("junior/stories/pix-stars.stories", function(){ return i("junior/stories/pix-stars.stories.js");});
d("junior/stories/pix-tag.stories", function(){ return i("junior/stories/pix-tag.stories.js");});
d("junior/stories/pix-textarea.stories", function(){ return i("junior/stories/pix-textarea.stories.js");});
d("junior/stories/pix-toggle.stories", function(){ return i("junior/stories/pix-toggle.stories.js");});
d("junior/stories/pix-tooltip.stories", function(){ return i("junior/stories/pix-tooltip.stories.js");});
d("junior/component-managers/glimmer", function(){ return i("junior/component-managers/glimmer.js");});
d("junior/initializers/app-version", function(){ return i("junior/initializers/app-version.js");});
d("junior/initializers/component-styles", function(){ return i("junior/initializers/component-styles.js");});
d("junior/instance-initializers/route-styles", function(){ return i("junior/instance-initializers/route-styles.js");});
d("junior/mixins/style-namespacing-extras", function(){ return i("junior/mixins/style-namespacing-extras.js");});
d("junior/data-adapter", function(){ return i("junior/data-adapter.js");});
d("junior/initializers/ember-data", function(){ return i("junior/initializers/ember-data.js");});
d("junior/transforms/date", function(){ return i("junior/transforms/date.js");});
d("junior/transforms/number", function(){ return i("junior/transforms/number.js");});
d("junior/services/intl", function(){ return i("junior/services/intl.js");});
d("junior/container-debug-adapter", function(){ return i("junior/container-debug-adapter.js");});
d("junior/components/banner/communication/component", function(){ return i("junior/components/banner/communication/component.js");});
d("junior/components/banner/communication/template", function(){ return i("junior/components/banner/communication/template.hbs");});
d("junior/components/bubble", function(){ return i("junior/components/bubble.js");});
d("junior/components/challenge/challenge-actions/component", function(){ return i("junior/components/challenge/challenge-actions/component.js");});
d("junior/components/challenge/challenge-actions/template", function(){ return i("junior/components/challenge/challenge-actions/template.hbs");});
d("junior/components/challenge/challenge-embed-simulator/component", function(){ return i("junior/components/challenge/challenge-embed-simulator/component.js");});
d("junior/components/challenge/challenge-embed-simulator/template", function(){ return i("junior/components/challenge/challenge-embed-simulator/template.hbs");});
d("junior/components/challenge/challenge-item-auto-reply/component", function(){ return i("junior/components/challenge/challenge-item-auto-reply/component.js");});
d("junior/components/challenge/challenge-item-qcm/component", function(){ return i("junior/components/challenge/challenge-item-qcm/component.js");});
d("junior/components/challenge/challenge-item-qcm/template", function(){ return i("junior/components/challenge/challenge-item-qcm/template.hbs");});
d("junior/components/challenge/challenge-item-qcu/component", function(){ return i("junior/components/challenge/challenge-item-qcu/component.js");});
d("junior/components/challenge/challenge-item-qcu/template", function(){ return i("junior/components/challenge/challenge-item-qcu/template.hbs");});
d("junior/components/challenge/challenge-item-qrocm/component", function(){ return i("junior/components/challenge/challenge-item-qrocm/component.js");});
d("junior/components/challenge/challenge-item-qrocm/template", function(){ return i("junior/components/challenge/challenge-item-qrocm/template.hbs");});
d("junior/components/challenge/challenge-media", function(){ return i("junior/components/challenge/challenge-media.js");});
d("junior/components/challenge/challenge-web-component/component", function(){ return i("junior/components/challenge/challenge-web-component/component.js");});
d("junior/components/challenge/challenge-web-component/template", function(){ return i("junior/components/challenge/challenge-web-component/template.hbs");});
d("junior/components/challenge/component", function(){ return i("junior/components/challenge/component.js");});
d("junior/components/challenge/item/component", function(){ return i("junior/components/challenge/item/component.js");});
d("junior/components/challenge/item/integration-test", function(){ return i("junior/components/challenge/item/integration-test.js");});
d("junior/components/challenge/item/template", function(){ return i("junior/components/challenge/item/template.hbs");});
d("junior/components/challenge/template", function(){ return i("junior/components/challenge/template.hbs");});
d("junior/components/footer/template", function(){ return i("junior/components/footer/template.hbs");});
d("junior/components/issue", function(){ return i("junior/components/issue.js");});
d("junior/components/markdown-to-html", function(){ return i("junior/components/markdown-to-html.js");});
d("junior/components/mission-card/completed-mission-card", function(){ return i("junior/components/mission-card/completed-mission-card.js");});
d("junior/components/mission-card/mission-card-background", function(){ return i("junior/components/mission-card/mission-card-background.js");});
d("junior/components/mission-card/mission-card-completed-background", function(){ return i("junior/components/mission-card/mission-card-completed-background.js");});
d("junior/components/mission-card/mission-card", function(){ return i("junior/components/mission-card/mission-card.js");});
d("junior/components/robot-dialog", function(){ return i("junior/components/robot-dialog.js");});
d("junior/components/popper-j-s", function(){ return i("junior/components/popper-j-s.js");});
d("junior/components/pix-background-header", function(){ return i("junior/components/pix-background-header.js");});
d("junior/components/pix-banner", function(){ return i("junior/components/pix-banner.js");});
d("junior/components/pix-block", function(){ return i("junior/components/pix-block.js");});
d("junior/components/pix-button-link", function(){ return i("junior/components/pix-button-link.js");});
d("junior/components/pix-button-upload", function(){ return i("junior/components/pix-button-upload.js");});
d("junior/components/pix-button", function(){ return i("junior/components/pix-button.js");});
d("junior/components/pix-checkbox", function(){ return i("junior/components/pix-checkbox.js");});
d("junior/components/pix-collapsible", function(){ return i("junior/components/pix-collapsible.js");});
d("junior/components/pix-filter-banner", function(){ return i("junior/components/pix-filter-banner.js");});
d("junior/components/pix-filterable-and-searchable-select", function(){ return i("junior/components/pix-filterable-and-searchable-select.js");});
d("junior/components/pix-icon-button", function(){ return i("junior/components/pix-icon-button.js");});
d("junior/components/pix-indicator-card", function(){ return i("junior/components/pix-indicator-card.js");});
d("junior/components/pix-input-code", function(){ return i("junior/components/pix-input-code.js");});
d("junior/components/pix-input-password", function(){ return i("junior/components/pix-input-password.js");});
d("junior/components/pix-input", function(){ return i("junior/components/pix-input.js");});
d("junior/components/pix-label-wrapped", function(){ return i("junior/components/pix-label-wrapped.js");});
d("junior/components/pix-label", function(){ return i("junior/components/pix-label.js");});
d("junior/components/pix-message", function(){ return i("junior/components/pix-message.js");});
d("junior/components/pix-modal", function(){ return i("junior/components/pix-modal.js");});
d("junior/components/pix-multi-select", function(){ return i("junior/components/pix-multi-select.js");});
d("junior/components/pix-pagination", function(){ return i("junior/components/pix-pagination.js");});
d("junior/components/pix-progress-gauge", function(){ return i("junior/components/pix-progress-gauge.js");});
d("junior/components/pix-radio-button", function(){ return i("junior/components/pix-radio-button.js");});
d("junior/components/pix-return-to", function(){ return i("junior/components/pix-return-to.js");});
d("junior/components/pix-search-input", function(){ return i("junior/components/pix-search-input.js");});
d("junior/components/pix-select", function(){ return i("junior/components/pix-select.js");});
d("junior/components/pix-selectable-tag", function(){ return i("junior/components/pix-selectable-tag.js");});
d("junior/components/pix-sidebar", function(){ return i("junior/components/pix-sidebar.js");});
d("junior/components/pix-stars", function(){ return i("junior/components/pix-stars.js");});
d("junior/components/pix-tag", function(){ return i("junior/components/pix-tag.js");});
d("junior/components/pix-textarea", function(){ return i("junior/components/pix-textarea.js");});
d("junior/components/pix-toggle", function(){ return i("junior/components/pix-toggle.js");});
d("junior/components/pix-tooltip", function(){ return i("junior/components/pix-tooltip.js");});
d("junior/components/fa-icon", function(){ return i("junior/components/fa-icon.js");});
d("junior/helpers/tests/index", function(){ return i("junior/helpers/tests/index.js");});
d("junior/helpers/page-title", function(){ return i("junior/helpers/page-title.js");});
d("junior/helpers/and", function(){ return i("junior/helpers/and.js");});
d("junior/helpers/eq", function(){ return i("junior/helpers/eq.js");});
d("junior/helpers/gt", function(){ return i("junior/helpers/gt.js");});
d("junior/helpers/gte", function(){ return i("junior/helpers/gte.js");});
d("junior/helpers/is-array", function(){ return i("junior/helpers/is-array.js");});
d("junior/helpers/is-empty", function(){ return i("junior/helpers/is-empty.js");});
d("junior/helpers/is-equal", function(){ return i("junior/helpers/is-equal.js");});
d("junior/helpers/lt", function(){ return i("junior/helpers/lt.js");});
d("junior/helpers/lte", function(){ return i("junior/helpers/lte.js");});
d("junior/helpers/not-eq", function(){ return i("junior/helpers/not-eq.js");});
d("junior/helpers/not", function(){ return i("junior/helpers/not.js");});
d("junior/helpers/or", function(){ return i("junior/helpers/or.js");});
d("junior/helpers/xor", function(){ return i("junior/helpers/xor.js");});
d("junior/helpers/pluralize", function(){ return i("junior/helpers/pluralize.js");});
d("junior/helpers/singularize", function(){ return i("junior/helpers/singularize.js");});
d("junior/helpers/app-version", function(){ return i("junior/helpers/app-version.js");});
d("junior/helpers/format-date", function(){ return i("junior/helpers/format-date.js");});
d("junior/helpers/format-list", function(){ return i("junior/helpers/format-list.js");});
d("junior/helpers/format-message", function(){ return i("junior/helpers/format-message.js");});
d("junior/helpers/format-number", function(){ return i("junior/helpers/format-number.js");});
d("junior/helpers/format-relative", function(){ return i("junior/helpers/format-relative.js");});
d("junior/helpers/format-time", function(){ return i("junior/helpers/format-time.js");});
d("junior/helpers/t", function(){ return i("junior/helpers/t.js");});
d("junior/modifiers/did-render", function(){ return i("junior/modifiers/did-render.js");});
d("junior/modifiers/on-click-outside", function(){ return i("junior/modifiers/on-click-outside.js");});
d("junior/modifiers/on-arrow-down-up-action", function(){ return i("junior/modifiers/on-arrow-down-up-action.js");});
d("junior/modifiers/on-enter-action", function(){ return i("junior/modifiers/on-enter-action.js");});
d("junior/modifiers/on-escape-action", function(){ return i("junior/modifiers/on-escape-action.js");});
d("junior/modifiers/on-space-action", function(){ return i("junior/modifiers/on-space-action.js");});
d("junior/modifiers/trap-focus", function(){ return i("junior/modifiers/trap-focus.js");});
d("junior/modifiers/did-insert", function(){ return i("junior/modifiers/did-insert.js");});
d("junior/modifiers/did-update", function(){ return i("junior/modifiers/did-update.js");});
d("junior/modifiers/will-destroy", function(){ return i("junior/modifiers/will-destroy.js");});
d("junior/routes/assessment", function(){ return i("junior/routes/assessment.js");});
d("junior/templates/assessment/challenge", function(){ return i("junior/templates/assessment/challenge.hbs");});
d("junior/controllers/assessment/challenge", function(){ return i("junior/controllers/assessment/challenge.js");});
d("junior/routes/assessment/challenge", function(){ return i("junior/routes/assessment/challenge.js");});
d("junior/templates/assessment/results", function(){ return i("junior/templates/assessment/results.hbs");});
d("junior/controllers/assessment/results", function(){ return i("junior/controllers/assessment/results.js");});
d("junior/routes/assessment/results", function(){ return i("junior/routes/assessment/results.js");});
d("junior/routes/assessment/challenge_unit-test", function(){ return i("junior/routes/assessment/challenge_unit-test.js");});
d("junior/routes/assessment/resume", function(){ return i("junior/routes/assessment/resume.js");});
d("junior/routes/assessment/resume_unit-test", function(){ return i("junior/routes/assessment/resume_unit-test.js");});
d("junior/templates/assessment/challenge-loading", function(){ return i("junior/templates/assessment/challenge-loading.hbs");});
d("junior/routes/identified", function(){ return i("junior/routes/identified.js");});
d("junior/templates/identified/missions/list", function(){ return i("junior/templates/identified/missions/list.hbs");});
d("junior/controllers/identified/missions/list", function(){ return i("junior/controllers/identified/missions/list.js");});
d("junior/routes/identified/missions/list", function(){ return i("junior/routes/identified/missions/list.js");});
d("junior/routes/identified/missions/mission", function(){ return i("junior/routes/identified/missions/mission.js");});
d("junior/templates/identified/missions/mission/index", function(){ return i("junior/templates/identified/missions/mission/index.hbs");});
d("junior/controllers/identified/missions/mission/index", function(){ return i("junior/controllers/identified/missions/mission/index.js");});
d("junior/templates/identified/missions/mission/introduction", function(){ return i("junior/templates/identified/missions/mission/introduction.hbs");});
d("junior/routes/identified/missions/mission/introduction", function(){ return i("junior/routes/identified/missions/mission/introduction.js");});
d("junior/routes/identified/missions/mission/resume", function(){ return i("junior/routes/identified/missions/mission/resume.js");});
d("junior/templates/organization-code", function(){ return i("junior/templates/organization-code.hbs");});
d("junior/controllers/organization-code", function(){ return i("junior/controllers/organization-code.js");});
d("junior/routes/organization-code", function(){ return i("junior/routes/organization-code.js");});
d("junior/routes/school", function(){ return i("junior/routes/school.js");});
d("junior/templates/school/students", function(){ return i("junior/templates/school/students.hbs");});
d("junior/controllers/school/students", function(){ return i("junior/controllers/school/students.js");});
d("junior/routes/school/students", function(){ return i("junior/routes/school/students.js");});
d("junior/templates/school/divisions", function(){ return i("junior/templates/school/divisions.hbs");});
d("junior/templates/application", function(){ return i("junior/templates/application.hbs");});
d("junior/routes/application", function(){ return i("junior/routes/application.js");});
d("junior/templates/challenge-preview", function(){ return i("junior/templates/challenge-preview.hbs");});
d("junior/routes/challenge-preview", function(){ return i("junior/routes/challenge-preview.js");});
d("junior/templates/error", function(){ return i("junior/templates/error.hbs");});
d("junior/routes/error", function(){ return i("junior/routes/error.js");});
d("junior/templates/not-found", function(){ return i("junior/templates/not-found.hbs");});
d("junior/routes/not-found", function(){ return i("junior/routes/not-found.js");});
d("junior/templates/school-error", function(){ return i("junior/templates/school-error.hbs");});
d("junior/routes/school-error", function(){ return i("junior/routes/school-error.js");});
d("junior/templates/loading", function(){ return i("junior/templates/loading.hbs");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("junior/instance-initializers/setup-fetch", function(){ return i("junior/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"API_HOST":"","BANNER_CONTENT":"Cette plateforme est destinée aux tests internes à Pix. Vos pix ne sont pas comptabilisés <br><a href=https://app.pix.fr>Allez sur le site Pix.</a>","BANNER_TYPE":"warning","EMBED_ALLOWED_ORIGINS":["https://epreuves.pix.fr","https://1024pix.github.io","https://epreuves-pr*.review.pix.fr"],"APP_VERSION":"dev","name":"junior","version":"4.214.0"});
}

